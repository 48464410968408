(function() {
  window.enable = function(element) {
    return element.removeClass('disabled').children().children().prop('disabled', false).children().prop('disabled', false).removeClass('disabled');
  };

  window.disable = function(element) {
    return element.addClass('disabled').children().children().prop('disabled', true).children().prop('disabled', true).addClass('disabled');
  };

  window.succeed = function(element) {
    return swap_class_ending(element, 'warning', 'success');
  };

  window.warn = function(element) {
    return swap_class_ending(element, 'success', 'warning');
  };

  window.swap_class_ending = function(element, oldEnding, newEnding) {
    var classList, className, i, len, results;
    classList = element.prop('classList');
    results = [];
    for (i = 0, len = classList.length; i < len; i++) {
      className = classList[i];
      if (className.endsWith(oldEnding)) {
        results.push(element.addClass(className.replace(oldEnding, newEnding)).removeClass(className));
      } else {
        results.push(void 0);
      }
    }
    return results;
  };

  $(document).on('turbolinks:load', function() {
    $('#upload-bulk-import-file').slideUp('slow');
    $('.checklist-item').change(function() {
      var next_element, next_element_id;
      next_element_id = $(this).attr('next-element-id');
      next_element = $("#" + next_element_id);
      if ($(this).prop('checked')) {
        enable(next_element);
        return succeed(next_element);
      } else {
        disable(next_element);
        return warn(next_element);
      }
    });
    $('#data-input-checkbox').change(function() {
      if ($(this).prop('checked')) {
        return $('#upload-bulk-import-file').slideDown('slow');
      } else {
        return $('#upload-bulk-import-file').slideUp('slow');
      }
    });
    return $('.has-checkbox').change(function() {
      var checkbox_element, checkbox_id;
      checkbox_id = $(this).attr('checkbox_id');
      checkbox_element = $("#" + checkbox_id);
      return checkbox_element.prop('checked', true).trigger('change');
    });
  });

}).call(this);
