(function() {
  $(document).on('turbolinks:load', function() {
    return $("a[data-log]").click(function(event) {
      var logEvent;
      logEvent = $(this).data("log");
      return amplitude.getInstance().logEvent(logEvent);
    });
  });

}).call(this);
