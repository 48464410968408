(function() {
  $(document).on('turbolinks:load', function() {
    return $('[id$=_workflow_id]').change(function() {
      var workflow_id;
      if ($(this).val()) {
        workflow_id = $(this).val();
        $('#preview_workflow_states').html(null).end();
        return $.ajax({
          url: "/workflows/" + workflow_id + "/state_names"
        }).done(function(json) {
          var spacer;
          spacer = ' &nbsp; <i class="fa fa-arrow-right "></i> &nbsp; ';
          return $('#preview_workflow_states').html(json.join(spacer));
        });
      }
    });
  });

}).call(this);
