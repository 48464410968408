import consumer from "./consumer"

consumer.subscriptions.create { channel: "NotificationsChannel" },
  connected: ->
    # Called when the subscription is ready for use on the server

  disconnected: ->
    # Called when the subscription has been terminated by the server

  received: (data) ->
    # Called when there's incoming data on the websocket for this channel
    $('#notifications').prepend(data.html)
    this.increment()
    this.highlight()

  increment: ->
    count = ($('#notifications-count').text() | 0) + 1
    $('div.notifications-count').html count

  highlight: ->
    $('#notifications-button')
    .removeClass('btn-primary btn-success btn-warning')
    .addClass('btn-danger')
