// The placeholder allows for advanced styling without breaking backwards compatibilty
addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  const html = `
    <div id="direct-upload-${id}" class="list-group-item direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `
  const file_name_hash = file.name.hash()
  const place_holder_element_id = 'direct-upload-item-' + file_name_hash + '-placeholder'
  if (document.getElementById(place_holder_element_id) !== null) {
    document.getElementById(place_holder_element_id).innerHTML = html
  } else {
    target.insertAdjacentHTML("beforebegin", html)
  }
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})
