(function() {
  $(document).on('turbolinks:load', function() {
    $('#package_project_id').change(function() {
      var project_id;
      project_id = $('#package_project_id').val();
      $('#package_user_id').children().remove().end();
      $.ajax({
        url: "/projects/" + project_id + "/possible_assignees"
      }).done(function(json) {
        var i, len, option, results;
        results = [];
        for (i = 0, len = json.length; i < len; i++) {
          option = json[i];
          results.push($('#package_user_id').append(new Option(option["display_name"], option["id"])));
        }
        return results;
      });
      return $.ajax({
        url: "/projects/" + project_id + ".json"
      }).done(function(json) {
        return $('#package_workflow_id').val(json["workflow_id"]).trigger("change");
      });
    });
    $('#package_vendor_name').autocomplete({
      source: $('#package_vendor_name').data('autocomplete-source')
    });
    $('#package_vendor_name').change(function() {
      var escaped_vendor, vendor;
      vendor = $('#package_vendor_name').val();
      escaped_vendor = vendor.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
      $.ajax({
        url: "/apps/autocomplete?vendor_name=" + escaped_vendor
      }).done(function(json) {
        return $('#package_app_name').autocomplete({
          source: json,
          select: function(event, ui) {
            return $('#package_app_id').val(ui.item.id);
          }
        });
      });
      $('#package_app_id').val(null);
      return $('#package_app_name').val(null);
    });
    $(".quick_note").click(function(e) {
      e.preventDefault();
      $("#note_note_type").val('comment').trigger('change');
      $("#note_title").val($(this).data('title'));
      return $("#note_note").val($(this).data('note'));
    });
    $('#note_type_info').hide();
    return $('#note_note_type').change(function() {
      if ($(this).val() === 'tip') {
        $('#note_type_info').show();
      }
      if ($(this).val() !== 'tip') {
        return $('#note_type_info').hide();
      }
    });
  });

}).call(this);
