(function() {
  $(document).on('turbolinks:load', function() {
    $("tr[data-link]").click(function(event) {
      if (!($(event.target).is('a') || $(event.target).is('i'))) {
        return window.location = this.dataset.link;
      }
    });
    return $('a[disabled=disabled]').click(function(event) {
      return event.preventDefault();
    });
  });

}).call(this);
