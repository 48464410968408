window.$ = window.jQuery = window.jquery = require("jquery");

import "moment";
import "chartkick";
import "chart.js";

// ***** START: ADDED BY KisoThemes *****
require("bootstrap");
require("metismenu/dist/metisMenu");
require("jquery-slimscroll");
require("bootstrap-switch");
require("jquery-countdown");
require("jquery-countto");
require("cd-easypiechart");
require("fastclick");
require("object-fit-images");
require("flot/source/jquery.canvaswrapper");
require("flot/source/jquery.flot");
require("gmaps.core");
require("jasny-bootstrap");
require("jvectormap");
require("jquery-parallax.js");
require("code-prettify");
require("prismjs");
require("sparklines");
require("clipboard");
// ***** END: ADDED BY KisoThemes *****

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("./channels");

// create global $ and jQuery variables for the external script
global.$ = global.jQuery = $;
import "./jira_feedback";

import "./amplitude";
import "./modal_js";
import "./modal";
import "./direct_uploads";
import "./string_hash";

require("./kiso_themes");

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
});

import "trix";
import "@rails/actiontext";
import "./tr_link";
import "./states";
import "./amplitude_data_tags";
import "./checklist";
import "./notifications";
import autocomplete from "jquery-ui/ui/widgets/autocomplete";
import "./packages";
import "./workflows";
import "./search";
// import './direct_upload_custom'
