(function() {
  var slice = [].slice;

  window.groupIt = function() {
    var chart, element, escapedGroupBy, groupBy, i, id, ids, ids_param, len, path, ref;
    element = arguments[0], groupBy = arguments[1], path = arguments[2], ids = 4 <= arguments.length ? slice.call(arguments, 3) : [];
    escapedGroupBy = groupBy.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
    ids_param = "";
    if (ids instanceof Array) {
      ref = ids[0];
      for (i = 0, len = ref.length; i < len; i++) {
        id = ref[i];
        ids_param = ids_param + "&id[]=" + id;
      }
    }
    chart = Chartkick.charts[element];
    return $.ajax({
      url: path + escapedGroupBy + ids_param
    }).done(function(json) {
      return chart.updateData(json);
    });
  };

  $(document).on('turbolinks:load', function() {
    return $("button[data-group-by]").click(function(e) {
      var groupBy, id, path;
      e.preventDefault();
      groupBy = $(this).data("group-by");
      path = $(this).parent().data("path");
      id = $(this).parent().data("id");
      groupIt("chart-1", groupBy, path, id);
      $(this).siblings().removeClass("active");
      return $(this).addClass("active");
    });
  });

}).call(this);
