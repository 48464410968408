$(document).on('show.bs.modal','.modal', function () {
  $('.hidden').hide();
})

$(document).on('show.bs.modal','#noteModal', function () {
  $(".quick_note").click(function(e) {
    e.preventDefault();
    $("#note_note_type").val('comment').trigger('change');
    $("#note_title").val($(this).data('title'));
    return $("#note_note").val($(this).data('note'));
  });
  $('#note_note_type').change(function() {
    if ($(this).val() === 'tip') {
      return $('#note_type_info').show();
    } else {
      return $('#note_type_info').hide();
    }
  });
})

$(document).on('shown.bs.modal','.modal', function () {
  $('.modal-submit').click(function() {
    return $('.modal').modal('hide');
  });
})