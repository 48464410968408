(function() {
  $(document).on('turbolinks:load', function() {
    return $("[data-behavior='notification-link']").click(function(e) {
      console.log('clicked');
      return $.ajax({
        url: "/notifications/" + e.currentTarget.id + "/mark_as_read",
        dataType: 'JSON',
        method: 'POST'
      });
    });
  });

}).call(this);
